import React from 'react';
import './App.less';
import 'antd-mobile/es/global'
import RouteApp from "@/config/router/route-app";
import {Provider} from "react-redux";
import store from "@/redux/store";

function App() {
    return (
        <React.Suspense>
            <Provider store={store}>
                <RouteApp/>
            </Provider>
        </React.Suspense>
    );
}

export default App;
