import Constant from "@/config/constant";

// @ts-ignore
import md5 from "md5";
import {HNavigateData} from "@/config/router/router-local";

const loginUserKey = Constant.Prefix + "_token"
const loginUserSign = Constant.Prefix + "_sign"

type UserParams = { token?: string, stat?: number, idNoText?: string, nickName?: string }

export class User {
    token: string;
    stat: number;
    exists: boolean;
    synced: boolean;

    constructor(param: UserParams) {
        let {token = "", stat = 1, idNoText = "", nickName = ""} = param
        this.token = token;
        this.stat = stat;
        this.exists = false;
        this.synced = false;
    }

    /**
     * 清除缓存
     */
    clear() {
        // @ts-ignore
        Object.keys(this).forEach(p => this[p] = undefined)
        this.exists = false;
        localStorage.removeItem(loginUserKey);
        localStorage.removeItem(loginUserSign);

    }

    /**
     * 同步缓存
     */
    storage() {
        const str = JSON.stringify(this)
        const sign = md5(str + Constant.Secret)
        localStorage.setItem(loginUserKey, str);
        localStorage.setItem(loginUserSign, sign);
    }

    /**
     * 修改登录信息
     * @param data
     */
    save(data: UserParams) {
        // @ts-ignore
        Object.keys(data).forEach(p => this[p] = data[p])
        this.storage()
    }

    /**
     * 懒加载获取User
     */
    sync(): User {
        if (this.exists) {
            return this;
        } else {
            this.reload()
            this.synced = false;
            return this;
        }
    }

    /**
     * 重载User
     */
    reload() {
        const str = localStorage.getItem(loginUserKey);
        if (str && str !== "") {
            const sign = md5(str + Constant.Secret)
            if (sign === localStorage.getItem(loginUserSign)) {
                const u: User = JSON.parse(str)
                // @ts-ignore
                Object.keys(this).forEach(p => this[p] = u[p])
            } else {
                this.clear()
                HNavigateData.Page503.go()
            }

        }
    }

    logout() {

    }

}

const LoginUser: User = new User({}).sync();
export default LoginUser;