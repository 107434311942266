import {useNavigate} from "react-router";
import React, {useEffect} from "react";
import Constant from "@/config/constant";
import {To} from "react-router-dom";
import {HNavigateData, loadRouter} from "@/config/router/router-local";
import {InitWxShare} from "@/common/wx-share";
import ConfigController from "@/api/ConfigController";
import {SetOutline} from "antd-mobile-icons"

let HRouteMedata = loadRouter()

export function InitializeNavigate() {
    let navigate = useNavigate()
    useEffect(() => {
        HRouteMedata.list.forEach(p => p.navigate = navigate)


        ConfigController.selectJsapi({
            showToast: false,
            body: {appId: "wxab84204c9b2970a8", url: window.location.href},
            success: (data) => {

                // @ts-ignore
                if (window.wx) {
                    // @ts-ignore
                    window.wx.config({
                        debug: false, // 为true时，就是调试模式，会弹出一些信息，正确、错误都会弹。
                        ...data,
                        // 必填，把要使用的方法名放到这个数组中。
                        jsApiList: [
                            'onMenuShareTimeline',
                            'onMenuShareAppMessage',
                            'onMenuShareQQ',
                            'onMenuShareQZone'
                        ]
                    });
                    InitWxShare("极迅电子签", window.location.href)
                }

            }
        })

    }, [navigate])
    useEffect(() => {
        if (window.location.pathname !== "/login" && !hasIdentityUrl(window.location.pathname)) {
            navigate(Constant.LoginPage + "?to=" + window.location.pathname)
        }
    }, [navigate])
    return <>
        <div style={{position: "fixed", bottom: "200px", zIndex: "1000", right: "10px"}}>
            <SetOutline string={"首页"} fontSize={38} color={"#000000"}
                        onClick={() => HNavigateData.Home.go()}/>

        </div>
    </>
}

export function hasEmpower(powerCode ?: string): boolean {
    if (Constant.Identity) {
        if (powerCode !== undefined && powerCode !== "") {

        }
    }

    return true;
}

export function hasIdentityUrl(toUrl?: To): boolean {
    return true;
}

export {HRouteMedata};

