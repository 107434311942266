import Http, {ReqProps} from "@/common/http";

export type TitleContent = {
    title: string
    content: string
}

class ConfigController {
    selectProblem(props: ReqProps<TitleContent[]>) {
        Http.doGet("/app/config/problem", props)
    }

    selectAbout(props: ReqProps<{ content: string }>) {
        Http.doGet("/app/config/about", props)
    }

    selectAgreementPrivacy(props: ReqProps<{ content: string }>) {
        Http.doGet("/app/agreement/privacy", props)
    }

    selectJsapi(props: ReqProps<{ appId: string, timestamp: string, nonceStr: string, signature: string }>) {
        Http.doPost("/message/wx/jsapi", props)
    }
}

export default new ConfigController()