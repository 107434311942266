import React from "react";
import {AnyParams} from "@/common/httptypes";
import {SpinLoading} from "antd-mobile";

const colors = [
    '#2f54eb',
    'rgb(246,179,7)',
    'rgb(80, 193, 233)',
    'rgb(169, 109, 243)',
    'rgb(245,97,58)',
    'rgb(103, 197, 12)',
    'rgb(80, 193, 233)',
    'rgb(110,58,120)',
];

export class Util {

    NotNull(data: any): boolean {
        return data !== null && data !== undefined && data !== '';
    }

    IsNull(data: any): boolean {
        return data === null || data === undefined || data === '';
    }

    Lazy(path?: string) {
        if (path) {
            const Comp = React.lazy(() => import(`@/${path}`));
            return (
                <React.Suspense fallback={<>   <SpinLoading/></>}>
                    <Comp/>
                </React.Suspense>
            );
        } else {
            return <></>
        }

    };


    Each(dataSource: AnyParams<any>[], childrenName: string, level: number, func: (element: AnyParams<any>, level: number) => void) {
        dataSource.forEach(p => {
            func(p, level)
            if (Array.isArray(p[childrenName])) {
                util.Each(p[childrenName], childrenName, level + 1, func)
            }
        })
    }

    getColor(str: string) {
        if (!str) return colors[0];
        return colors[str.charCodeAt(0) % colors.length];
    }

    joinUrl(url: string, queryString?: string) {
        if (queryString) {
            if (url.indexOf("?") !== -1) {
                if (queryString.startsWith("?")) {
                    return url + queryString.substring(1)
                } else {
                    return url + "&" + queryString
                }
            } else {
                if (queryString.startsWith("?")) {
                    return url + queryString
                } else {
                    return url + "?" + queryString
                }
            }
        }
        return url;
    }

    deepClone<T>(input: T): T {
        if (input === undefined || input === null) {
            return input
        } else {
            return JSON.parse(JSON.stringify(input))
        }
    }

    getDateText() {
        // 获取当前时间
        let timeNow = new Date();
        // 获取当前小时
        let hours = timeNow.getHours();
        // 设置默认文字
        let text = ``;
        // 判断当前时间段
        if (hours >= 6 && hours <= 9) {
            text = `早上好`;
        } else if (hours >= 9 && hours <= 12) {
            text = `上午好`;
        } else if (hours > 12 && hours <= 14) {
            text = `中午好`;
        } else if (hours > 14 && hours <= 18) {
            text = `下午好`;
        } else {
            text = `晚上好`;
        }
        // 返回当前时间段对应的状态
        return text;
    }
}


export const util = new Util();