import {BrowserRouter} from 'react-router-dom';

type constant = {
    BaseUrl: string; // 接口基本访问地址
    LoginPage: string; // 登录页地址
    Prefix: string; // 项目前缀
    Identity: boolean; // 是否启动本地路由
    Secret: string; // 密钥
    WebsiteName: string; // 项目名称
    LayoutHome: string; // 首页地址
    NODE_ENV: string; // 当前环境
    RouterType: any; // 路由类型
    EmpowerVisible: boolean,
    AuthorizeUrl: string,
}

const Constant: constant = {
    ...require("./.env." + process.env.REACT_APP_ENV + ".tsx").App,
    RouterType: BrowserRouter,
    NODE_ENV: String(process.env.REACT_APP_ENV)
};
export default Constant;
