import {RouteElement, RouterMetadata} from "@/config/router/router-types";
import {AnyParams} from "@/common/httptypes";

import {AppOutline, TextOutline, UserOutline} from 'antd-mobile-icons'
import React from "react";

export const HNavigateData = {
    Login: new RouteElement({name: "登陆页", path: "/login", element: "pages/login"}),
    Home: new RouteElement({
        name: "首页",
        layout: true,
        path: "/home",
        icon: <AppOutline/>,
        element: "pages/tabs/home"
    }),
    Receipt: new RouteElement({
        name: "合同",
        layout: true,
        icon: <TextOutline/>,
        path: "/receipt",
        element: "pages/tabs/receipt"
    }),
    Me: new RouteElement({name: "我的", layout: true, path: "/me", icon: <UserOutline/>, element: "pages/tabs/me"}),
    IdentityPage: new RouteElement({
        name: "登陆/注册",
        path: "/identity",
        element: "pages/option-me/identity/index"
    }),
    ChangePwdForm: new RouteElement({
        name: "更改密码",
        path: "/change-pwd",
        element: "pages/option-me/change-pwd/index"
    }),
    RestPwdForm: new RouteElement({
        name: "重置密码",
        path: "/rest-pwd",
        element: "pages/option-me/change-pwd/rest-pwd"
    }),

    FormLoan: new RouteElement({
        name: "电子借条",
        path: "/form-loan",
        element: "pages/contract-form/form-loan"
    }), FormRenovation: new RouteElement({
        name: "装修合同",
        path: "/form-renovation",
        element: "pages/contract-form/form-renovation"
    }), FormPartTime: new RouteElement({
        name: "兼职合同",
        path: "/form-part-time",
        element: "pages/contract-form/form-part-time"
    }),
    ProblemPage: new RouteElement({
        name: "常见问题",
        path: "/problem",
        element: "pages/option-me/problem/index"
    }),
    AboutAs: new RouteElement({
        name: "关于我们",
        path: "/about",
        element: "pages/option-me/about/index"
    }),
    ContractQrCode: new RouteElement({
        name: "合同二维码",
        path: "/contract/qrcode",
        element: "pages/contract/contract-qrcode"
    }),
    ContractDetail: new RouteElement({
        name: "合同详情",
        path: "/contract/detail",
        element: "pages/contract/contract-detail"
    }),
    ContractInner: new RouteElement({
        name: "合同记录",
        path: "/contract/inner",
        element: "pages/contract/contract-inner"
    }),
    ContractExt: new RouteElement({
        name: "合同展期",
        path: "/contract/ext",
        element: "pages/contract/contract-ext"
    }),
    LawyerLetter: new RouteElement({
        name: "律师函",
        path: "/lawyer/letter",
        element: "pages/supported/lawyer-letter"
    }),
    CreditQueryPage: new RouteElement({
        name: "信用查询",
        path: "/credit/query",
        element: "pages/supported/credit-query"
    }),
    CreditDetailPage: new RouteElement({
        name: "信用详情",
        path: "/credit/detail",
        element: "pages/supported/credit-detail"
    }),

    Page503: new RouteElement({name: "503", path: "/503"}),
    Page404: new RouteElement({name: "404", path: "/404"}),

}

export function SwitchGoPage(url: string) {
    const u = new URL(url)
    for (let k in HNavigateData) {
        // @ts-ignore
        const data = HNavigateData[k] as RouteElement
        if (u.pathname === data.path) {
            data.go({replace: true}, u.search)
        }
    }
}

export function loadRouter(): RouterMetadata {
    const elementMapping = HNavigateData as ({ [key: string]: RouteElement })

    const list: RouteElement[] = []
    const mapping: AnyParams<RouteElement> = {}
    const layout: RouteElement[] = []

    Object.keys(elementMapping).forEach(p => {
        const element = elementMapping[p]
        list.push(element)
        mapping[element.path] = element

        if (element.layout) {
            layout.push(element)
        }

    })
    return {list, mapping, layout}
}