import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ParseResponse, ReqProps} from "@/common/http";

export interface UserInfo {
    metadata: {
        nickName: string;
        idNoText: string
        stat: number
        trusteeship: boolean
        loading: boolean
    }
}

export type AsyncSelectMethod = (props: ReqProps<any>) => Promise<Response>;

function initialState(): UserInfo {
    return {
        metadata: {
            nickName: "xxx",
            idNoText: "xxxxxxxxxxxxxxxxxx",
            stat: 0,
            trusteeship: false,
            loading: false,
        },
    }
}


export const asyncThunkSelectUserinfo = createAsyncThunk(
    'userinfo/selectData',
    async (action: { method: AsyncSelectMethod }) => {
        const response = await action.method({})
        const data = await ParseResponse(response)
        return data !== undefined ? data : {}
    }
);


const userInfoSlice = createSlice({
    name: 'redux/userinfo',
    initialState: initialState(),
    reducers: {
        logout(state, action) {
            state.metadata = initialState().metadata
        }

    },

    extraReducers: (builder) => {
        builder
            .addCase(asyncThunkSelectUserinfo.pending, (state) => {
                state.metadata.loading = true;
            }).addCase(asyncThunkSelectUserinfo.fulfilled, (state, action) => {
            state.metadata = {...action.payload, loading: false}

        });
    },
});
export default userInfoSlice;
