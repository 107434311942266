import {Navigate, Route, Routes} from 'react-router-dom';
import React, {useMemo} from 'react';
import Constant from '@/config/constant';
import constant from '@/config/constant';
import {HRouteMedata, InitializeNavigate} from "@/config/router/route-loader";
import ERR404 from "@/config/404";
import Progress from "@/components/nprogress";
import ERR503 from "@/config/503";
import HLayout from "@/components/ant-ext-layout/HLayout";
import {RouteElement} from "@/config/router/router-types";
import LoginUser from "@/config/user";

export default function RouteApp() {
    const routes = useMemo(() => {

        if (constant.NODE_ENV !== "development") {
            const params = new URLSearchParams(window.location.search);
            if (!params.get("wxId") && !LoginUser.token) {
                window.location.href = constant.AuthorizeUrl + "?url=" + window.location.href
                return;
            }
        }

        const router = [];
        HRouteMedata.list.forEach((p, i) => {
            if (p.element) {
                router.push(<Route key={i} path={p.path}
                                   element={lazy(p)}/>);
            }
        })
        router.push(<Route key={"any"} path={'*'} element={<ERR404/>}/>);
        router.push(<Route key={"404"} path={'/404'} element={<ERR404/>}/>);
        router.push(<Route key={"503"} path={'/503'} element={<ERR503/>}/>);
        router.push(<Route key={"/"} path={'/'} element={<Navigate to={Constant.LayoutHome}/>}/>);
        return router;
    }, []);
    return <>
        <Constant.RouterType>
            <Routes>
                {routes}
            </Routes>
        </Constant.RouterType>

    </>
}


function lazy(element: RouteElement) {
    let Comp = React.lazy(() => import(`@/${element.element}`));
    if (element.layout) {
        return (
            <HLayout>
                <InitializeNavigate/>
                <React.Suspense fallback={<>   <Progress/></>}>
                    <Comp/>
                </React.Suspense>
            </HLayout>


        )
    } else {
        return (
            <>
                <InitializeNavigate/>
                <React.Suspense fallback={<>   <Progress/></>}>
                    <Comp/>
                </React.Suspense>
            </>
        );

    }


}
