import {AnyParams} from "@/common/httptypes";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {NavigateOptions} from "react-router/dist/lib/context";
import qs, {stringify} from "qs";
import {util} from "@/common/util";

export type RouteData = {
    link: RouteElement[],
    layout: RouteElement[],
}


export class RouteElement {
    name: Readonly<string>;
    icon?: Readonly<string>;
    path: Readonly<string>;
    identity?: Readonly<boolean>;
    element?: Readonly<string>;
    layout?: Readonly<boolean>;
    navigate ?: NavigateFunction

    // @ts-ignore
    constructor(props: RouteElement.prototype) {
        this.name = props.name
        this.icon = props.icon
        this.path = props.path
        this.identity = props.identity
        this.element = props.element
        this.layout = props.layout
    }

    go(option?: NavigateOptions,queryString?:string) {
        if (queryString){
            this.navigate!(util.joinUrl(this.path,queryString), option)
        }else {
            this.navigate!(this.path, option)
        }

    }


}

export type RouterMetadata = {
    list: RouteElement[];
    mapping: AnyParams<RouteElement>;
    layout: RouteElement[];
}