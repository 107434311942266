import {configureStore} from "@reduxjs/toolkit";
import userInfoSlice from "@/redux/userinfo-slice";


const store = configureStore({
    reducer: {
        userinfo: userInfoSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;