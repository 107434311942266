import React from "react";
import {TabBar} from "antd-mobile";
import {HRouteMedata} from "@/config/router/route-loader";
import "./HLayout.less"

const tabs = HRouteMedata.layout.map(p => {
    return {key: p.path, title: p.name, icon: p.icon}
})
export default function HLayout(props: { children: React.ReactNode }) {


    return <>
        <div className="app">
            <div className="body">
                {props.children}
            </div>

            <div className="bottom">
                <TabBar activeKey={window.location.pathname} onChange={(v) => HRouteMedata.mapping[v].go()}>
                    {tabs.map(item => (
                        <TabBar.Item key={item.key} icon={item.icon} title={item.title}/>
                    ))}
                </TabBar>
            </div>
        </div>

    </>

}